import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const DockerCourseVerifyEmailPage = () => {
  const meta = {
    bodyAttributes: {
      class: "c-recaptcha",
    },
    lang: "mk",
    title: "Потврда на е-пошта | Docker for Developers: курс за програмери",
  }

  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false)

  const [verificationChecked, setVerificationChecked] = useState(false)
  const [msg, setMsg] = useState(`Вашата електронска адреса се потврдува!`)
  const [success, setSuccess] = useState()

  const getRecaptchaToken = () => {
    return window.grecaptcha.execute(process.env.GATSBY_RECAPTCHA_CLIENT_KEY, {
      action: "homepage",
    })
  }

  const handleRecaptchaLoaded = () => {
    window.grecaptcha.ready(() => {
      setRecaptchaLoaded(true)
    })
  }

  useEffect(() => {
    const script = document.createElement("script")
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_CLIENT_KEY}`
    script.addEventListener("load", handleRecaptchaLoaded)
    document.body.appendChild(script)
  }, [])

  useEffect(() => {
    const doMagic = async () => {
      const token = await getRecaptchaToken()
      const params = new URLSearchParams(document.location.search.substring(1))
      const apiUrl = `${process.env.GATSBY_API_URL}/docker-for-developers/verify-email`

      try {
        const { errors = [] } = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token, // recaptcha token
            verifyToken: params.get("token"),
          }),
        }).then(r => r.json())

        if (errors.length) {
          setMsg(
            `Грешка при проверка на е-пошта. Осигурајте се дека линкот е валиден!`
          )
        } else {
          setSuccess(true)
          setMsg(`Успешно потврдена е-пошта!`)
        }
      } catch (err) {
        console.log("wrong: ", err.message)
        setSuccess(false)
        setMsg(`Грешка при барањето за потврда на е-пошта.`)
      } finally {
        setVerificationChecked(true)
      }
    }

    if (recaptchaLoaded) {
      doMagic()
    }
  }, [recaptchaLoaded])

  return (
    <Layout hasHeader={false}>
      <Seo {...meta} />

      <section className="hero is-fullheight">
        <div className="hero-body">
          <div className="container is-max-desktop">
            <div className="columns">
              <div className="column is-three-quarters">
                <h1 className="title c-hero-title is-1 pt-5">
                  Потврда на е-пошта{" "}
                  {verificationChecked &&
                    (success ? (
                      <span role="img" aria-label="success">
                        🎉
                      </span>
                    ) : (
                      <span role="img" aria-label="failure">
                        ❌
                      </span>
                    ))}
                </h1>
                <p className="subtitle is-4 has-text-link has-text-weight-light pt-3">
                  {msg}{" "}
                  {!verificationChecked && (
                    <span className="button is-link is-loading"></span>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default DockerCourseVerifyEmailPage
